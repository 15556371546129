//mobile
.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      padding-left: 40px;

      a {
        h2 {
          max-width: 11em;
          font-size: 1.3em;
          font-weight: 400;
          text-transform: uppercase;
        }
        //Modifiche per il logo 2025 190 anni. Da togliere quando il logo ritornerà alla versione standard
        .icon {
          width: auto;
          height: 38px;
        }
      }
    }
  }
}

@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        padding-left: 32px;
        a .icon {
          width: auto;
          height: 70px;
        }
      }
    }
  }
}
